const TwitterNew = ({ ...props }) => {
  return (
    <svg {...props} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM22.3655 13.16C22.3655 17.4946 19.0645 22.4944 13.0311 22.4944C11.2475 22.4954 9.50121 21.9839 8 21.0208C8.26015 21.0524 8.52195 21.0685 8.78401 21.0689C10.2617 21.0712 11.6972 20.5761 12.8593 19.6633C12.1748 19.6495 11.5117 19.4224 10.9625 19.0136C10.4133 18.6049 10.0054 18.0348 9.79573 17.3831C10.2869 17.4781 10.7934 17.4589 11.276 17.3268C10.533 17.1773 9.86481 16.7751 9.3849 16.1886C8.90498 15.602 8.64298 14.8674 8.64345 14.1096V14.069C9.09947 14.3214 9.609 14.4616 10.1299 14.4782C9.43604 14.014 8.9449 13.3031 8.75634 12.4898C8.56777 11.6766 8.69594 10.822 9.11479 10.0999C9.93889 11.1127 10.9666 11.941 12.1314 12.5311C13.2962 13.1213 14.5719 13.46 15.876 13.5255C15.7134 12.822 15.787 12.0846 16.0855 11.4271C16.384 10.7697 16.8907 10.2289 17.5274 9.88837C18.1641 9.54783 18.8952 9.42651 19.6077 9.54317C20.3203 9.65983 20.9745 10.008 21.4694 10.5337C22.2038 10.3885 22.9081 10.1192 23.5521 9.73756C23.3066 10.497 22.794 11.1417 22.1094 11.552C22.7596 11.4765 23.3945 11.3027 23.9926 11.0366C23.5524 11.6956 22.9983 12.2708 22.3561 12.7352C22.3624 12.8758 22.3655 13.0195 22.3655 13.16Z"
        fill="white"
      />
    </svg>
  )
}

export default TwitterNew
